import type {ReactElement, ReactNode} from 'react';
import React, { useEffect, useLayoutEffect} from 'react';
import {autoUpdate, flip, offset, shift, useDismiss, useFloating, useInteractions, useRole} from '@floating-ui/react';
import {PopoverDefaultAlignment} from '@Components/popover/popover.types';
import {isEntirelyInViewport} from '@Utils/browser.util';
import styles from './popover.module.scss';

const DEFAULT_OFFSET = 4;

export interface PopoverProps {
  referenceElementRef: React.RefObject<HTMLElement>;
  children: ReactNode;
  popoverCloseFunction: () => void;
  alignment?: PopoverDefaultAlignment;
  popoverOffset?: number;
  scrollAnchorIntoView?: boolean;
  className?: string;
}

export function Popover({
  referenceElementRef,
  children,
  popoverCloseFunction,
  alignment = PopoverDefaultAlignment.BOTTOM_START,
  popoverOffset = DEFAULT_OFFSET,
  scrollAnchorIntoView = true,
  className = '',
}: PopoverProps): ReactElement {
  const smoothScrollAnchorIntoViewIfNeeded = (): void => {
    const doesAnchorNeedToBeScrolledIntoView = scrollAnchorIntoView && referenceElementRef.current && !isEntirelyInViewport(referenceElementRef);
    if (doesAnchorNeedToBeScrolledIntoView) {
      referenceElementRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  const {x, y, refs, context, strategy} = useFloating({
    open: true,
    onOpenChange: popoverCloseFunction,
    strategy: 'fixed',
    placement: alignment,
    middleware: [offset(popoverOffset), flip(), shift()],
    whileElementsMounted: autoUpdate,
  });

  useLayoutEffect(() => {
    refs.setReference(referenceElementRef.current);
  }, [refs, referenceElementRef.current]);

  useEffect(() => {
    smoothScrollAnchorIntoViewIfNeeded();
  }, []);

  const dismiss = useDismiss(context);
  const role = useRole(context);

  // Merge all the interactions into prop getters
  const {getFloatingProps} = useInteractions([dismiss, role]);

  return (
    <div
      ref={refs.setFloating}
      style={{
        position: strategy,
        top: y ?? 0,
        left: x ?? 0,
      }}
      className={`${styles.popover} ${className}`}
      {...getFloatingProps()}
    >
      {children}
    </div>
  );
}
