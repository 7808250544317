import {repoURL, getReadBucket} from '@Libraries/s3-library';
import {getUnixTimestamp} from '@Utils/date.util';
import {Deferred} from '@Libraries/deferred';
import {getCacheBustedURL} from '@Utils/url.util';
import type {EmbeddedEditorCallback} from '@Libraries/embedded-editor';
import type {CreateDesignFromUploadOpts} from '@Components/design-selector/design-selector.types';
import {isNumber} from 'lodash';
import type {UploadMediaIntroPopupSeenResponse} from '@Libraries/poster-type-library';

export const PREVIEW_BUCKET = 'posterpreviews';

export enum PosterPreviewExtension {
  IMAGE = 'jpg',
  VIDEO = 'mp4',
}

export enum PosterPreviewSize {
  THUMB = 'THUMB',
  SCREEN = 'SCREEN',
}

const UPLOAD_MEDIA_POPUP_SEEN_CACHE_PREFIX = 'upload_media_popup_seen_';

/**
 * Creates a URL to a resource in the S3 repository.
 */
export const getPreviewURL = (hashedId: string, extension: string, size: PosterPreviewSize = PosterPreviewSize.THUMB): string => {
  const cacheBustTs = getUnixTimestamp(new Date());
  let sizeSuffix = '';

  if (size) {
    sizeSuffix = '_screen';
  }

  return repoURL(`${PREVIEW_BUCKET}/${hashedId}${sizeSuffix}.${extension}?ts=${cacheBustTs}`, getReadBucket());
};

export const loadDesignSelector = (
  designsLoadedCallback: () => void,
  onDesignSelected: EmbeddedEditorCallback,
  divToLoadIn: string,
  loadPostersIfNotLoadedCustomEvent: string,
  startDesignFromUploadBtnOpts?: CreateDesignFromUploadOpts
): void => {
  window.PMW.util.require(window.PMW.loadDesignSelectorElement === undefined, 'designSelector', true, () => {
    window.PMW.loadDesignSelectorElement(designsLoadedCallback, onDesignSelected, divToLoadIn, loadPostersIfNotLoadedCustomEvent, startDesignFromUploadBtnOpts);
  });
};

export const loadDesignGallery = async (contentElementSelector: string, contentContainerSelector: string, disableCreateFromScratchCTA: boolean): Promise<unknown> => {
  const deferred = new Deferred();

  window.PMW.util.require(window.PMW.initGalleryContentInElement === undefined, 'galleryDialog', true, () => {
    window.PMW.initGalleryContentInElement(true, contentElementSelector, contentContainerSelector, disableCreateFromScratchCTA);
    deferred.resolve();
  });

  return deferred.promise;
};

export const pollForUpdatesInURL = (url: string, refetchForPlaceholderPreview = true): Deferred => {
  let referenceTime = new Date().getTime();
  let iteration = 0;
  const MAX_ITERATIONS = 300;
  const ITERATION_TIMEOUT_MS = 2000;
  const totalAssetsFetched = 10; // The first URL fetched is generally a placeholder image.
  let assetsFetched = 0;
  const deferred = new Deferred();
  const urlObj = new URL(url);

  if (!urlObj) {
    deferred.reject();
    return deferred;
  }

  const intervalHandler = setInterval((): void => {
    if (iteration >= MAX_ITERATIONS) {
      clearInterval(intervalHandler);
      deferred.resolve();
    }

    const ajax = new XMLHttpRequest();
    ajax.open('HEAD', `${getCacheBustedURL(urlObj.href)}`, true);
    ajax.onerror = (): void => {
      console.log('error!');
    };

    // eslint-disable-next-line func-names
    ajax.onreadystatechange = function (): void {
      if (this.readyState === this.DONE) {
        if (typeof ajax.getResponseHeader('last-modified') !== 'undefined') {
          // poll for img
          const newLastModified = ajax.getResponseHeader('last-modified');

          if (newLastModified) {
            const lastModifiedTime = Date.parse(newLastModified);

            if (lastModifiedTime > referenceTime) {
              if (refetchForPlaceholderPreview && assetsFetched < totalAssetsFetched) {
                console.log('Fetched asset, Counter:', assetsFetched);
                assetsFetched += 1;
                return;
              }
              console.log('clearInterval');

              clearInterval(intervalHandler);
              deferred.resolve();
            }
          }
        }
      }
    };

    ajax.onerror = (): void => {
      clearInterval(intervalHandler);
      deferred.reject();
    };

    iteration += 1;
    ajax.send();
  }, ITERATION_TIMEOUT_MS);

  return deferred;
};

export const pollForPosterPreviewChanges = (designHashedId: string, isVideo = false): Deferred => {
  const url = getPosterPreviewURL(designHashedId, isVideo);

  return pollForUpdatesInURL(url);
};

export const getPosterPlaceholderImagePreviewURL = (): string => {
  return window.PMW.util.asset_url('images/landing-page/placeholder-image.png');
};
export const getPosterPlaceholderVideoPreviewURL = (): string => {
  return window.PMW.util.asset_url('images/landing-page/placeholder-video.mp4');
};

export const getPosterPreviewURL = (designHashedId: string, isVideo = false, size = PosterPreviewSize.SCREEN): string => {
  const extension = isVideo ? PosterPreviewExtension.VIDEO : PosterPreviewExtension.IMAGE;

  return getPreviewURL(designHashedId, extension, size);
};

const getUploadMediaPopupSeenCacheKey = (): string => {
  return UPLOAD_MEDIA_POPUP_SEEN_CACHE_PREFIX + (window.PMW.getUserId() as string);
};

const setUploadMediaPopupSeenCache = (value: number): void => {
  window.PMW.setCacheItem(getUploadMediaPopupSeenCacheKey(), value);
};

const getUploadMediaPopupSeenCache = (): boolean | null => {
  const cachedValue = window.PMW.getCacheItem(getUploadMediaPopupSeenCacheKey()) as string | null;

  if (cachedValue === null) {
    return null;
  }

  return parseInt(cachedValue, 10) === 1;
};

export const hasUserSeenUploadMediaPopup = async (): Promise<boolean> => {
  const cachedValue = getUploadMediaPopupSeenCache();

  if (cachedValue !== null) {
    return cachedValue;
  }

  if (!window.PMW.isUserLoggedIn()) {
    return false;
  }

  try {
    const data = (await window.PMW.readLocal('posters/getHasUserSeenUploadMediaIntroPopup')) as UploadMediaIntroPopupSeenResponse;
    data.hasSeen = !isNumber(data.hasSeen) ? parseInt(data.hasSeen, 10) : data.hasSeen;

    setUploadMediaPopupSeenCache(data.hasSeen);
    return data.hasSeen === 1;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const markUserHasSeenUploadMediaPopup = async (): Promise<void> => {
  try {
    await window.PMW.writeLocal('posters/updateUserHasSeenUploadMediaIntroPopup');

    setUploadMediaPopupSeenCache(1);
  } catch (error) {
    console.error(error);
  }
};
