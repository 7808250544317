import type {SocialAccount} from '@Components/social-media/account.vo';
import type {SocialMediaAccountId, SocialPost, FacebookMediaType, InstagramMediaType, MediaTypePublishingParams, MediaTypeLoadingStates} from '@Components/social-media/post.vo';
import {
  GoogleBusinessProfilePostType,
  LinkedInPrivacySettings,
  PostState,
  PrivacySettings,
  TikTokPublicAccountPrivacySettings,
  YouTubePrivacySettings,
} from '@Components/social-media/post.vo';
import type {PinterestBoard, WizardTemplateFlowInfo} from '@Components/social-media-wizard/social-media-wizard.types';
import {captionCharacterLimits, SocialMediaWizardStep} from '@Components/social-media-wizard/social-media-wizard.types';
import type {
  FacebookPublishingParams,
  GoogleBusinessProfilePublishingParams,
  InstagramPublishingParams,
  LinkedInPublishingParams,
  PinterestPublishingParams,
  SocialMediaPublishingParams,
  TikTokPublishingParams,
  TwitterPublishingParams,
  YouTubePublishingParams,
  ThreadsPublishingParams,
  SocialPostCover,
} from '@Components/social-media/publish-params.types';
import {PublishingMediaType, SocialPostPublishingAction} from '@Components/social-media/publish-params.types';
import type {PosterNeedsResizeInformation, PosterResizeInformationHashMap} from '@Components/social-media/social-media.types';
import {PosterResizeRequiredStatus, SocialPlatform} from '@Components/social-media/social-media.types';
import {CropOption, ResizeOption, ResizeOptionsConfig} from '@Components/social-media-wizard/components/social-media-resize-options/social-media-resize-options.types';
import {LoadingStates} from '@Utils/loading.util';
import type {SocialPostCoverParams} from '@Panels/social-media-select-cover-panel/social-media-select-cover-panel.types';
import {getFlattenedSocialPostCoverParams} from '@Panels/social-media-select-cover-panel/social-media-select-cover-panel.types';

export const MAX_ACCOUNT_BUTTONS_TO_SHOW = 5;
export const WIZARD_HEADER_CLASS = 'js-social-media-wizard-header';
const getStepForAccounts = (accounts: SocialAccount[]): SocialMediaWizardStep => {
  if (accounts.length > 0) {
    return SocialMediaWizardStep.SELECT_ACCOUNTS;
  }

  return SocialMediaWizardStep.CONNECT_ACCOUNTS;
};

export const getLoadingTextByPublishingAction = (publishingAction: SocialPostPublishingAction): string => {
  switch (publishingAction) {
    case SocialPostPublishingAction.PUBLISH:
      return window.i18next.t('pmwjs_publishing');
    case SocialPostPublishingAction.SCHEDULE:
      return window.i18next.t('pmwjs_scheduling');
    case SocialPostPublishingAction.SAVE:
      return window.i18next.t('pmwjs_saving');
    default:
      return window.i18next.t('pmwjs_publishing');
  }
};

export const getFirstWizardStep = (): SocialMediaWizardStep => {
  return Object.keys(SocialMediaWizardStep)[0] as SocialMediaWizardStep;
};

export const doesWizardStepNeedProgressOnBar = (step: SocialMediaWizardStep, accounts: SocialAccount[] = []): boolean => {
  switch (step) {
    case SocialMediaWizardStep.SELECT_DESIGN:
      return true;
    case SocialMediaWizardStep.CONNECT_ACCOUNTS:
      return accounts.length === 0;
    case SocialMediaWizardStep.ACCOUNT_CONNECTION:
      return false;
    case SocialMediaWizardStep.SELECT_ACCOUNTS:
      return true;
    case SocialMediaWizardStep.ADD_DETAILS:
      return true;
    case SocialMediaWizardStep.PUBLISH:
      return false;
    default:
      return false;
  }
};

export const isVisualResizeNeeded = (resizeStatus: PosterResizeRequiredStatus): boolean => {
  return resizeStatus === PosterResizeRequiredStatus.RESIZE_NEEDED;
};

export const haveAllPublishingStatesLoaded = (
  accountIdsToPublishTo: number[],
  publishingStatusForAllAccounts: Record<SocialMediaAccountId, MediaTypeLoadingStates>,
  publishingParams: Record<SocialMediaAccountId, MediaTypePublishingParams>
): boolean => {
  for (let i = 0; i < accountIdsToPublishTo.length; i++) {
    const accountId = accountIdsToPublishTo[i];

    const reelParams = publishingParams[accountId].REELS;
    const reelLoadingState = publishingStatusForAllAccounts[accountId].REELS;

    const postParams = publishingParams[accountId].POST;
    const postLoadingState = publishingStatusForAllAccounts[accountId].POST;

    const storyParams = publishingParams[accountId].STORIES;
    const storyLoadingState = publishingStatusForAllAccounts[accountId].STORIES;

    if (reelParams && reelLoadingState === LoadingStates.LOADING) {
      return false;
    }

    if (postParams && postLoadingState === LoadingStates.LOADING) {
      return false;
    }

    if (storyParams && storyLoadingState === LoadingStates.LOADING) {
      return false;
    }
  }

  return true;
};

export const isVisualResizeNeededForAnyDesignForAccount = (posterResizeInfoForAccount: PosterResizeInformationHashMap): boolean => {
  return Object.values(posterResizeInfoForAccount).some((posterResizeInfo: PosterNeedsResizeInformation) => {
    return isVisualResizeNeeded(posterResizeInfo.needsResizing);
  });
};

export const isFullResizeNeeded = (resizeStatus: PosterResizeRequiredStatus): boolean => {
  return resizeStatus === PosterResizeRequiredStatus.RESIZE_NEEDED || resizeStatus === PosterResizeRequiredStatus.BACKEND_OPTIMIZATION_NEEDED;
};

export const getAccountForId = (accounts: SocialAccount[], socialAccountId: SocialMediaAccountId): SocialAccount | null => {
  let matchedAccount = null;
  accounts.forEach((account) => {
    if (socialAccountId === account.id) {
      matchedAccount = account;
    }
  });

  return matchedAccount;
};

export const getEmptyPublishingParamsForAccount = (account: SocialAccount): SocialMediaPublishingParams | null => {
  let publishingParams: SocialMediaPublishingParams | null;

  switch (account.type) {
    case SocialPlatform.FACEBOOK:
    case SocialPlatform.FACEBOOK_PAGE:
    case SocialPlatform.FACEBOOK_PROFILE:
    case SocialPlatform.FACEBOOK_GROUP:
      publishingParams = {
        caption: '',
        idSocialMediaAccount: account.id,
        title: '',
        publishedURL: null,
        publishErrorMessage: null,
        state: PostState.DRAFT,
        isOriginalDeleted: false,
      } as FacebookPublishingParams;
      break;
    case SocialPlatform.INSTAGRAM:
      publishingParams = {
        caption: '',
        idSocialMediaAccount: account.id,
        publishedURL: null,
        publishErrorMessage: null,
        state: PostState.DRAFT,
        isOriginalDeleted: false,
      } as InstagramPublishingParams;
      break;
    case SocialPlatform.YOUTUBE:
      publishingParams = {
        caption: '',
        idSocialMediaAccount: account.id,
        privacySettings: YouTubePrivacySettings.PUBLIC,
        title: '',
        publishedURL: null,
        publishErrorMessage: null,
        state: PostState.DRAFT,
        isOriginalDeleted: false,
      } as YouTubePublishingParams;
      break;
    case SocialPlatform.TWITTER:
      publishingParams = {
        caption: '',
        idSocialMediaAccount: account.id,
        publishedURL: null,
        publishErrorMessage: null,
        state: PostState.DRAFT,
        isOriginalDeleted: false,
      } as TwitterPublishingParams;
      break;
    case SocialPlatform.LINKEDIN:
    case SocialPlatform.LINKEDIN_PAGE:
    case SocialPlatform.LINKEDIN_PROFILE:
      publishingParams = {
        caption: '',
        idSocialMediaAccount: account.id,
        privacySettings: LinkedInPrivacySettings.PUBLIC,
        title: '',
        publishedURL: null,
        publishErrorMessage: null,
        state: PostState.DRAFT,
        isOriginalDeleted: false,
      } as LinkedInPublishingParams;
      break;
    case SocialPlatform.TIKTOK:
      publishingParams = {
        privacySettings: TikTokPublicAccountPrivacySettings.PUBLIC_TO_EVERYONE,
        disableComment: false,
        disableDuet: false,
        disableStitch: false,
        isPromotingThirdParty: false,
        isSelfBrand: false,
        caption: '',
        title: '',
        idSocialMediaAccount: account.id,
        publishedURL: null,
        publishErrorMessage: null,
        state: PostState.DRAFT,
        isOriginalDeleted: false,
      } as TikTokPublishingParams;
      break;
    case SocialPlatform.PINTEREST:
      publishingParams = {
        title: '',
        caption: '',
        websiteLink: '',
        idSocialMediaAccount: account.id,
        publishedURL: null,
        state: PostState.DRAFT,
        publishId: null,
        publishErrorMessage: null,
        boardId: null,
        privacySettings: PrivacySettings.PUBLIC,
        graphicsMeta: {},
        isOriginalDeleted: false,
      } as PinterestPublishingParams;
      break;
    case SocialPlatform.GOOGLE_BUSINESS_PROFILE:
      publishingParams = {
        caption: '',
        postType: GoogleBusinessProfilePostType.STANDARD,
        ctaType: null,
        ctaURL: '',
        eventStartTime: null,
        eventEndTime: null,
        idSocialMediaAccount: account.id,
        publishedURL: null,
        publishErrorMessage: null,
        publishId: null,
        state: PostState.DRAFT,
        graphicsMeta: {},
        isOriginalDeleted: false,
      } as GoogleBusinessProfilePublishingParams;
      break;
    case SocialPlatform.THREADS:
      publishingParams = {
        caption: '',
        idSocialMediaAccount: account.id,
        publishedURL: null,
        publishErrorMessage: null,
        state: PostState.DRAFT,
        graphicsMeta: {},
        isOriginalDeleted: false,
      } as ThreadsPublishingParams;
      break;
    default:
      publishingParams = null;
  }

  return publishingParams;
};

const isSpecificTemplateNotSelected = (galleryFlowInfo: WizardTemplateFlowInfo | null): boolean => {
  return !galleryFlowInfo || (galleryFlowInfo && !galleryFlowInfo.templateId);
};

export const getNextWizardStep = (
  post: SocialPost | undefined = undefined,
  accounts: SocialAccount[] = [],
  step: SocialMediaWizardStep | null = null,
  galleryFlowInfo: WizardTemplateFlowInfo | null = null
): SocialMediaWizardStep | null => {
  if (!step && isSpecificTemplateNotSelected(galleryFlowInfo)) {
    if (!post) {
      return SocialMediaWizardStep.SELECT_DESIGN;
    }

    return getStepForAccounts(accounts);
  }

  if (!step && galleryFlowInfo && galleryFlowInfo.templateId) {
    return getStepForAccounts(accounts);
  }

  switch (step) {
    case SocialMediaWizardStep.SELECT_DESIGN:
      return getStepForAccounts(accounts);
    case SocialMediaWizardStep.CONNECT_ACCOUNTS:
      return SocialMediaWizardStep.ACCOUNT_CONNECTION;
    case SocialMediaWizardStep.ACCOUNT_CONNECTION:
      return SocialMediaWizardStep.SELECT_ACCOUNTS;
    case SocialMediaWizardStep.SELECT_ACCOUNTS:
      if (galleryFlowInfo && (galleryFlowInfo.isGalleryFlow || galleryFlowInfo.templateId)) {
        return SocialMediaWizardStep.EDIT_TEMPLATE;
      }

      return SocialMediaWizardStep.ADD_DETAILS;

    case SocialMediaWizardStep.EDIT_TEMPLATE:
      return SocialMediaWizardStep.ADD_DETAILS;
    case SocialMediaWizardStep.ADD_DETAILS:
      return SocialMediaWizardStep.PUBLISH;
    default:
      return null;
  }
};

export const isMediaTypeSupportedForAccount = (accountPlatform: SocialPlatform, mediaType: PublishingMediaType): boolean => {
  switch (accountPlatform) {
    case SocialPlatform.FACEBOOK:
    case SocialPlatform.FACEBOOK_PAGE:
    case SocialPlatform.FACEBOOK_PROFILE:
    case SocialPlatform.FACEBOOK_GROUP:
    case SocialPlatform.INSTAGRAM:
    case SocialPlatform.TWITTER:
    case SocialPlatform.LINKEDIN:
    case SocialPlatform.LINKEDIN_PAGE:
    case SocialPlatform.LINKEDIN_PROFILE:
    case SocialPlatform.THREADS:
      return true;

    case SocialPlatform.YOUTUBE:
    case SocialPlatform.TIKTOK:
      return mediaType === PublishingMediaType.VIDEO;

    case SocialPlatform.GOOGLE_BUSINESS_PROFILE:
      return mediaType === PublishingMediaType.IMAGE;

    default:
      return false;
  }
};

export const getResizeOptionsConfigFromResizeOptions = (resizeOption: ResizeOption = ResizeOption.FILL, cropOption: CropOption = CropOption.CROP_TOP): ResizeOptionsConfig => {
  if (resizeOption === ResizeOption.FILL) {
    return ResizeOptionsConfig.FILL;
  }

  if (resizeOption === ResizeOption.BLUR) {
    return ResizeOptionsConfig.BLUR;
  }

  if (resizeOption === ResizeOption.STRETCH) {
    return ResizeOptionsConfig.STRETCH;
  }

  if (resizeOption === ResizeOption.CROP) {
    if (cropOption === CropOption.CROP_TOP) {
      return ResizeOptionsConfig.CROP_TOP;
    }

    if (cropOption === CropOption.CROP_CENTER) {
      return ResizeOptionsConfig.CROP_CENTER;
    }

    if (cropOption === CropOption.CROP_BOTTOM) {
      return ResizeOptionsConfig.CROP_BOTTOM;
    }
  }

  return ResizeOptionsConfig.FILL;
};

export const getResizeConfigForGATracking = (resizeOptionsConfig: ResizeOptionsConfig): string => {
  switch (resizeOptionsConfig) {
    case ResizeOptionsConfig.BLUR:
      return 'blur';

    case ResizeOptionsConfig.FILL:
      return 'fill';

    case ResizeOptionsConfig.STRETCH:
      return 'stretch';

    case ResizeOptionsConfig.CROP_BOTTOM:
      return 'crop_bottom';

    case ResizeOptionsConfig.CROP_TOP:
      return 'crop_top';

    case ResizeOptionsConfig.CROP_CENTER:
      return 'crop_center';

    default:
      return '';
  }
};

export const getCharacterLimitForAccountPost = (account: SocialAccount): number => {
  switch (account.type) {
    case SocialPlatform.TWITTER:
      return account.isVerifiedTwitterUser ? 10000 : captionCharacterLimits[SocialPlatform.TWITTER];
    default:
      return captionCharacterLimits[account.type];
  }
};

export const getFacebookMediaType = (publishingParams: SocialMediaPublishingParams, posterHashedId: string): FacebookMediaType | null => {
  const graphicsMeta = publishingParams.graphicsMeta[posterHashedId];

  if (graphicsMeta && 'mediaType' in graphicsMeta) {
    return graphicsMeta.mediaType as FacebookMediaType;
  }

  return null;
};

export const getInstagramMediaType = (publishingParams: SocialMediaPublishingParams, posterHashedId: string): InstagramMediaType | null => {
  const graphicsMeta = publishingParams.graphicsMeta[posterHashedId];

  if (graphicsMeta && 'mediaType' in graphicsMeta) {
    return graphicsMeta.mediaType as InstagramMediaType;
  }

  return null;
};

export const getPinterestBoards = async (pinterestAccountId: number): Promise<PinterestBoard[]> => {
  return (await window.PMW.readLocal('/socialmedia/getPinterestBoardsForUser', {pinterestAccountId})) as PinterestBoard[];
};

export const createPinterestBoard = async (pinterestAccountId: number, boardName: string): Promise<PinterestBoard> => {
  return (await window.PMW.readLocal('/socialmedia/createPinterestBoardsForUser', {
    pinterestAccountId,
    boardName,
  })) as PinterestBoard;
};

export const generateCoverForPost = async (socialPostCoverParams: SocialPostCoverParams): Promise<SocialPostCover> => {
  const flattenedSocialPostCoverParams = getFlattenedSocialPostCoverParams(socialPostCoverParams);

  return (await window.PMW.writeLocal(`socialmedia/generateCoverForPost`, {...flattenedSocialPostCoverParams})) as SocialPostCover;
};
