import type {ReactElement, ReactNode} from 'react';

export enum PopoverDefaultAlignment {
  BOTTOM_CENTER = 'bottom',
  BOTTOM_START = 'bottom-start',
  BOTTOM_END = 'bottom-end',
  TOP_CENTER = 'top',
  TOP_START = 'top-start',
  TOP_END = 'top-end',
  RIGHT_CENTER = 'right',
  RIGHT_START = 'right-start',
  RIGHT_END = 'right-end',
  LEFT_CENTER = 'left',
  LEFT_START = 'left-start',
  LEFT_END = 'left-end',
}

export interface CustomPopoverActions {
  isPopoverOpen?: boolean;
  openPopover?: () => void;
  closePopover?: () => void;
  togglePopover?: () => void;
}

export interface PopoverActions {
  isPopoverOpen: boolean;
  openPopover: () => void;
  closePopover: () => void;
  togglePopover: () => void;
  renderPopover: (children: ReactNode) => ReactElement | null;
}
